$color-text-light: #ffffff;
$color-text-dark: #262626;

@function get-height($width, $ratio, $height) { 
   $percen: (274 / 359) * 100;
   $dynamic-width: ($width/100) * $percen;
   $vw-context: $dynamic-width / $ratio;
   @return (($vw-context - 116) * $height) * 1px;
 }

/*
 * CSS Reset
 **/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
   margin: 0;
   padding: 0;
   border: 0;
   font-size: 100%;
   font: inherit;
   vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
   display: block;
}
body {
   line-height: 1.5;
   font-family: 'BMWTypeNext Light';
}

button {
   font-family: 'BMWTypeNext Light';
}

ul, ol {
   margin-bottom: 1em;
}

ul {
   list-style: disc;
   padding-left: 20px;
}

blockquote, q {
   quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
   content: '';
   content: none;
}
table {
   border-collapse: collapse;
   border-spacing: 0;
}
// html, body, div#root {
//    height: 100%;
// }
strong {
   font-family: 'BMWTypeNext Bold';
}
h1 {
   font-family: 'BMWTypeNext Light';
   font-size: 48px;
   margin-top: -10px;
}
h3 {
   font-family: 'BMWTypeNext Light';
   font-size: 24px;
   line-height: 2;
}
p {
   font-family: 'BMWTypeNext Light';
   font-size: 16px;
   line-height: 1.7;
}
span {
   font-family: 'BMWTypeNext Light';
   font-size: 16px;
}
.segment-page {
   padding: 40px 25px;
}
.color-white {
   color: #fff;
}
.color-dark-gray {
   color: #262626;
}
.uppercase {
   text-transform: uppercase;
}
.font-large {
   font-size: 64px;
}
.font-bold {
   font-family: 'BMWTypeNext Bold';
   font-weight: bold;
}
.font-small {
   font-size: 14px;
}
.font-tiny {
   font-size: 12px;
}
.margin-top-10 {
   margin-top: 10px;
}
.margin-top-20 {
   margin-top: 20px;
}
.margin-bottom-35 {
   margin-bottom: 35px;
}
.text-center {
   text-align: center;
}


.text-padding {
   padding: 16px;
   @media screen and (min-width: 600px) {
      padding: 32px;
   }
}


/*
 * UI ( button, input, p, ul, li)
 **/
button {
  &:disabled {
     opacity: 0.3;
     cursor: default;
  } 
}

.border-button {
   border: 2px solid #bbbbbb;
   width: 85px;
   height: 30px;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: transparent;
   outline: none;
   padding: 0 0 3px 0;
   transition: all 0.3s ease-out;
   &:active {
      transform: scale(0.95);
   }
}

.normal-button {
   border: 2px solid #0653b6;
   background-color: #0653b6;
   
   width: 100%;
   height: 50px;
   display: flex;
   align-items: center;
   justify-content: center;
   outline: none;
   transition: all 0.3s ease-out;
   text-decoration: none;
   &:active {
      transform: scale(0.95);
   }
}


.secondary-button {
   border: 2px solid #0653b6;
   background-color: transparent;
   color: #0653b6;
   width: 100%;
   height: 50px;
   display: flex;
   align-items: center;
   justify-content: center;
   outline: none;
   transition: all 0.3s ease-out;
   text-decoration: none;
   &:active {
      transform: scale(0.95);
   }
}

.close-button {
   width: 48px;
   z-index: 1;
   padding: 0;
   border: none;
   outline: none;
   background-color: transparent;
} 


.text-button {
   border: none;
   background: none;
   outline: none;
   cursor: pointer;
   &.active {
      font-family: 'BMWTypeNext Bold';
      font-weight: bold;
      text-decoration: underline;
   }
}

.close-icon {
   right: 15px;
   width: 48px;
   transition: all 0.3s ease-out;
   &:active {
      transform: scale(0.9);
   }
}

.tag {
   background: rgba(29, 104, 212, 0.19);
   color: rgb(29, 104, 212);
   line-height: 29px;
   height: 31px;
   padding: 0px 22px;
   display: inline-block;
   font-size: 14px;
   border-radius: 10px;
}

.logo-wrapper {
   position: absolute;
   top: 40px;
   left: 25px;
}

/*
 * Components
 **/ 
.app-loading {
   display: flex;
   width: 100vw;
   height: 100vh;
   justify-content: center;
   align-items: center;
}
.header {
   display: flex;
   width: 100%;

   .close-button-position {
      position: fixed;
      top: 25px;
      right: 15px;
   }
}

.flat-card-wrapper {
   width: 100%;
   background-color: #fff;
   box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.2);
   margin: 0 0 16px;
   position: relative;
   transition: all 0.3s ease-out;

   .name-text {
      position: absolute;
      top: 10px;
      left: 15px;
      z-index: 20;
      
   }

   &.card-active {
      &:before {
         content: '';
         background-color: #1c60d44c;
         width: 100%;
         height: 100%;
         position: absolute;
         z-index: 50;
      }
   }
   &:active {
      .thumbnail-image {
         transform: scale(0.90);
      }
   }
   .thumbnail-image {
      display: block;
      transition: all 0.3s ease-out;
      width: 100%;
   }
   
   .card-active {
      background-color: #1c60d44c;
   }
 }

 /*
  * PAGES
  **/

.body-page-1 {
   &.page-series-2 {
      letter-spacing: 0px;

      .page-header {
         background: url('/assets/images/series2-topbanner.jpg') no-repeat center top;
         background-size: cover;

         @media only screen and ( -webkit-min-device-pixel-ratio: 1.3 ),
         only screen and (    min--moz-device-pixel-ratio: 1.3 ),
         only screen and (      -o-min-device-pixel-ratio: 2.6/2 ), /* returns 1.3, see Dev.Opera */
         only screen and (         min-device-pixel-ratio: 1.3 ),
         only screen and ( min-resolution: 124.8dpi ),
         only screen and ( min-resolution: 1.3dppx ) {
            background: url('/assets/images/series2-topbanner@2x.jpg') no-repeat center top;
            background-size: cover;
         }

         min-height: 220px;
         height: 47vw;
         color: white;
         position: relative;

         .page-header__gradient {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 60vw;
            background: linear-gradient(90deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
         }

         .page-header__inner {
            position: relative;
            padding: 24px;
            text-transform: uppercase;

            .logo {
               position: absolute;
               top: 24px;
               right: 24px;

            }
            h1 {
               font-size: 28px;
               line-height: 1.3;
               margin: 0.1em 0 0.3em;
            }

            p {
               font-size: 14px;
            }

            @media (min-width: 992px) {
               h1 { font-size: 3.6em; }
               p { font-size: 1.5em; }
            }

            @media (min-width: 1200px) {
               padding: 48px 96px;

               h1 { font-size: 3.6em; margin-top: 2em; }
               p { font-size: 1.5em; }
            }
         }
      }

      .page-content {
         padding: 12px 24px;
         color: #262626;

         &.max-width-400 {
            margin: 0 auto;
            max-width: 400px;
         }

         h2 { font-size: 18px; }
         h3 { font-size: 15px; }
         .text-sm { font-size: 10px; }
         img {
            display: block;
            margin: 0 auto;
            max-width: 100%;
         }
         
         @media (min-width: 500px) {
            h2 { font-size: 24px; }
         }
      }

      .page-canvas {
         width: 100%;
         
         img {
            max-width: 100%;
         }
         .elem__all {
            display: block;
            width: 100%;
            max-width: 1000px;
            margin:0 auto;
         }
      }

      .content__term {
         margin-top: 2em;
      }

      .page-footer {
         background: #E6E6E6;
         padding: 24px;
         width: auto;
         display: flex;
         flex-direction: column;
         align-items: center;
         text-align: center;

         @media (min-width: 640px) {
            text-align: left;
            flex-direction: row;
            justify-content: space-between;
         }

         .nav-title {
            padding: 6px 12px;
         }
         ul {
            list-style: none;
            padding-left: 0;
            margin: 0.6em 0 1em;
            li {
               display: inline-block;
               padding: 6px 12px;

               a {
                  color: #8D8D8D;

                  &.active {
                     color: #000000;
                  }
               }
            }
         }
      }
   }
   // .page-bg {
   //    position: absolute;
   //    top: 0;
   //    left: 0;
   //    bottom: 0;
   //    right: 0;
   //    background: url('/assets/images/first-page-bg.jpg') no-repeat center top;
   //    background-size: cover;
   // }
   
   // .language-button {
   //    position: absolute;
   //    top: 40px;
   //    right: 20px;
   //    color: white;
   //    @media screen and (min-width: 600px) {
   //       color: $color-text-dark;
   //       right: 40px;
   //    }
   // }
   // .logo-wrapper {
   //    display: flex;
   // }
   // .logo-text-wrapper {
   //    margin-left: 15px;
   //    line-height: 1;
   // }
   // .header-wrapper {
   //    width: 100%;
   //    height: 160px;
   //    position: absolute;
   //    z-index: 1;
   //    display: flex;
   //    justify-content: space-between;
   // }
   // .page-content {
   //    position: absolute;
   //    bottom: 50px;
   //    right: 25px;
   //    left: 25px;
   //    max-width: 500px;
   //    h3 {
   //       line-height: 1.1;
   //       margin: 1em 0;
   //    }
   // }
   // @media screen and (min-width: 600px) {
   //    .segment-page {
   //       padding: 0;
   //       display: flex;
   //       height: 100%;
   //    }
   //    .page-bg {
   //       position: static;
   //       flex: 1;
   //    }
   //    .page-content {
   //       flex: 0 0 400px;

   //       padding: 40px;
   //       display: flex;
   //       flex-direction: column;
   //       justify-content: flex-end;
   //       position: static;
   //       h3, p {
   //          color: $color-text-dark;
   //       }
   //    }
   // }
}

.body-page-2 {
   background-image: radial-gradient(circle at 50% 50%, var(--white-two), #000000 181%);
   height: 100%;
   overflow: hidden;

   .slider__container {
      padding-top: 92px;
      min-height: 76vh;

      // opacity: 0; // Animation
   }

   .flickity-button {
      display: none;
   }
   
   .Slider {
      width: 100vw;
      height: 76vh;
   }
   
   .Slide {
      width: 40vh;
      height: 70vh;
   }

   .slider-wrapper {
      border-radius: 15px;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
      background-color: #fff;
      position: relative;
      margin-top: 15px;
      overflow: hidden;
      .separate-char {
         letter-spacing: 25px;
         padding: 20px 30px 0px 30px;
         @media screen and (max-width: 320px) {
            position: absolute;
            z-index: 1;
            padding: 5px 20px 0px 20px;
         }
      }
      .font-big {
         font-size: 148px;
         font-weight: 300;
         line-height: 0.7;
         padding: 0px 30px 0px 30px;
         position: absolute;
         top: 70px;
         z-index: 10;
         @media screen and (max-width: 375px) {
            font-size: 120px;
         }
         @media screen and (max-width: 320px) {
            font-size: 68px;
            top: 51px;
            padding: 0px 20px 0px 20px;
         }
      }
      .slider-image-container {
         height: 50vh;
         position: relative;
      }
      .slider-image {
         // width: 100%;
         position: absolute;
         z-index: 0;
         top: 22%;
         height: 100%;
         // height: 50vh;
         top: 13%;
         left: -9%;

         user-drag: none; 
         user-select: none;
         -moz-user-select: none;
         -webkit-user-drag: none;
         -webkit-user-select: none;
         -ms-user-select: none;
         
         @media screen and (max-width: 375px) {
            top: 18%;
         }
         @media screen and (max-width: 320px) {
            top: 5%;
         }
      }
      .slider-bottom {
         position: absolute;
         bottom: 0;
         background: rgb(255,255,255);
         background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 15%, rgba(255,255,255,1) 100%);
         width: calc(100% - 30px);
         border-radius: 0 0 15px 15px;
         padding: 5px 15px 20px 15px;
         box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.2);
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;

         h3 {
            font-size: 20px;
         }
      }
      .separate-line {
         border-top: 1px solid #6d7278;
         width: 100%;
         margin-bottom: 5px;
      }
   }

   .flickity-page-dots {
      display: flex;
      align-items: center;
      justify-content: center;
      .dot {
         background: #bbbbbb;
         opacity: 1;
         &.is-selected {
            width: 12px ;
            height: 12px;
            background: #1c60d4;
         }
      }
      @media screen and (max-width: 320px) {
         bottom: -7px;
      }
   }

   .dots-text-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      @media screen and (max-width: 320px) {
         margin-top: 10px;
      }
   }
}

.body-page-3 {
   position: relative;
   .logo-wrapper {
      position: absolute;
      top: 40px;
      left: 25px;
      z-index: 1;
   }
   .coverimage-container {
      height: 68vw;
      background-size: cover; // 100%;
      background-repeat: no-repeat;
      background-position: center center;
   }
   .show-case-image {
      width: 100%;
   }
   .content-wrapper {
      padding-top: 20px;
      padding-bottom: 30px;

      h3 {
         font-size: 20px;
      }
   }
   .title-wrapper {
      padding-left: 15px;
      margin-bottom: 1em;
   }
   .button-wrapper {
      padding: 30px 15px;
   }

   .options__container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .option__item {
         flex: 1 100%;

         @media (min-width: 640px) {
            flex: 0 1 50%;
         }
         @media (min-width: 1024px) {
            flex: 0 1 33%;
         }
         @media (min-width: 1200px) {
            flex: 0 1 25%;
         }
      }
   }
}

.body-page-4 {
   position: relative;
   height: 100%;

   .close-button-position {
      position: fixed;
      top: 25px;
      right: 15px;
   }
   .ar-button {
      box-sizing: border-box;
      background-color: #0653b6;
      border-radius: 17.5px;
      border: solid 2px #0653b6;
      color: #fff;
      line-height: 20px;
      padding: 6px 10px 10px 10px;
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translate(-50%, 0);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      min-width: 125px;
      outline: none;
      z-index: 12;
      transition: all 0.3s ease-out;
      text-decoration: none;
      // transform-origin: 50% 50%;
      &:active {
         transform: translate(-50%, 0) scale(0.95);
      }

      &.left {
         left: 15px;
         right: auto;
      }

      img {
         position: relative;
         top: 2px;
      }
   }

   .cta-button {
      width: 100%;
      margin-bottom: 1em;

      @media (min-width: 768px) {
         width: 408px;
      }
   }
   .button-icon {
      width: 22px;
      margin-right: 6px;
   }

   .content__container {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100vw;

      .content__inner {
         padding: 15px;
         img {
            max-width: 100%;
            margin: 1em auto 2em;
         }

         .content__inner-actions {
            display: flex;

            button {
               margin-left: 15px;
               margin-bottom: 0;
               &:first-of-type {
                  margin-left: 0;
               }
            }
            
         }
      }

      model-viewer {
         width: 100%;
         height: 400px;
         flex: 1;
      }

      model-viewer:focus {
         outline: none;
       }       

      .bottom-actions {
         flex: 0;
         padding: 1em 10px 0;
         box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.24);
      }

      @media screen and (min-width: 768px) {
         flex-direction: row;
         model-viewer {
            flex: 1 ;
            height: 100%;
         }

         .content__inner {
            flex: 0 300px;
            display: flex;
            flex-direction: column;
            justify-content: center;
         }
      }
   }
}

.body-thank-you {
   position: relative;
   height: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   .close-button-position {
      position: absolute;
      top: 25px;
      right: 15px;
   }
}

.desktop-page {
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   .logo-wrapper-right {
      position: absolute;
      top: 20px;
      right: 5%;
   }
   .sample-image {
      height: 75vh;
   }
   .content-wrapper {
      display: flex;
      
   }
   .text-wrapper {
      margin-top: 20vh;
      margin-right: 30px;
   }
}

.simple-field {
   width: 100%;
   height: 48px;
   border: solid 2px #bbbbbb;
   background-color: #fff;
   font-family: 'BMWTypeNext Light';
   font-size: 14px;
   padding: 0 20px 5px 20px;
   outline: none;
   display: flex;
   align-items: center;
   margin-bottom: 8px;
   color: #262626;

   &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #262626;
   }
   &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #262626;
   }
   &::-ms-input-placeholder { /* Microsoft Edge */
      color: #262626;
   }
}

.icon-field-wrapper {
   position: relative;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-bottom: 8px;
   .button-in-field {
      position: absolute;
      width: 24px;
      height: 24px;
      right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: transparent;
      outline: none;
      transition: all 0.3s ease-out;
      &:active {
         transform: scale(0.9);
      }
   }
   .button-icon-field {
      width: 24px;
   }
   .icon-field {
      margin-bottom: 0;
   }
   .check-icon-field {
      position: absolute;
      width: 15px;
      right: 20px;
   }
}


// Tabs
.tab-header {
   display: flex;
}
.tab-button {
   position: relative;
  background: white;
  border: none;
  flex: 1 0 120px;
  padding: 16px 0;
  font-size: 14px;
  text-align: center;
  color: #262626;
  border-bottom: 3px solid white;
  outline: none;
  transition: all 0.3s;
  &:focus {
     box-shadow: none;
  }
  &:active {
     opacity: 0.7;
     transform: scale(0.9);
  }
  &.active {
     border-bottom: 3px solid #1c60d4;
  }
}

.tab-container {
   width: 100%;
   height: calc(100vh - 306px);

   .tab {
      height: 100%;
      display: none;
   }
   .active {
      display: block;
   }
}

.offer {
   font-family: 'BMWTypeNext Light';
   padding-bottom: 100px;
   display: flex;
   flex-direction: column;
   justify-content: center;

   img {
      max-width: 100%;
   }
   strong {
      font-family: 'BMWTypeNext Bold';
   }
   h2 {
      font-size: 24px;
      font-family: 'BMWTypeNext Light';
      font-weight: normal;
      text-transform: uppercase;
      margin-bottom: 20px;
   }

   p { margin-bottom: 1em; }
}